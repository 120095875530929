import { useEffect, useRef, useState } from "react";
import { Dispo } from "../components/DispoPicker";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { ShoppingCardSession } from "../models/ShoppingCard";
import { ProposedCoach } from "../models/ProposedCoach";
import { RecapSeance } from "../components/RecapSeance";
import { Location } from "../models/Location";
import Coachs from "../components/coachs";
import { addPacktoShop, pickCoach } from "../services/reservationService";
import { Banniere } from "../components/shared/banniere";
import NotreSelection from "../components/NotreSelection";
import { getPromotion } from "../services/cardService";
import { Reduction } from "../models/Reduction";
import { getReductionValue } from "../utils/manyUtils";
import RechercheSeance from "../components/rechercheSeance";
import CoachDuMois from "../components/coachDuMois";
import Cours from "../components/cours";
import { Box, Button, Typography } from "@mui/material";
import classes from "../style";
import { showMessage, showModal } from "../services/popupService";
import { AddPackType } from "../models/ReservationProposal";
import { LoggedType } from "../slices/loginSlice";
import { ADD_TO_CARD, LOAD_CARD, SET_DISPOS, SET_LOCATION, SET_RESERVATIONS, SET_SPECIALITY } from "../slices/reservationSlice";
import { PurchasedPack } from "../models/Pack";
import { ReductionBanner } from "../components/reductionBanner/ReductionBanner";
import { DefiscalisationBanner } from "../components/defiscalisationBanner/DefiscalisationBanner";
import { useHistory, useLocation } from "react-router";

const ClientMainPage: React.FC = () => {
  const state = useSelector((s: RootState) => s);
  const history = useHistory();
  const dispatch = useDispatch();
  const [speciality, setSpecialityReservation] = useState<number>(-1);
  const [location, setLocationReservation] = useState<Location>();
  const uri = useLocation().pathname;

  const [dispos, setDisposReservations] = useState<Dispo[]>([]);

  const [userChoose, setUserChoose] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [proposition, setProposition] = useState<ProposedCoach | undefined>(
    undefined
  );

  const [adding, setAdding] = useState(false);
  const [reservations, setProposedReservations] = useState<ProposedCoach[]>([]);
  const [loading, setLoading] = useState(false);

  const ref = useRef<null | HTMLDivElement>(null);
  const scroll = () => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ block: 'end', inline: 'end' });
    }
  };

  const setReservations = (res: ProposedCoach[]) => {
    setProposedReservations(res);
    dispatch(SET_RESERVATIONS(res));
  }

  const setSpeciality = (spe: number) => {
    setSpecialityReservation(spe);
    dispatch(SET_SPECIALITY(spe));
  }

  const setDispos = (dispos: Dispo[]) => {
    setDisposReservations(dispos);
    dispatch(SET_DISPOS(dispos));
  }

  const setLocation = (loc: Location) => {
    setLocationReservation(loc);
    dispatch(SET_LOCATION(loc));
  }

  const addPack = async () => {
    setAdding(true);

    try {
      const r = await addPacktoShop();

      if (r.result == AddPackType.OK) {
        if (state.login.loggedType === LoggedType.Client) {
          dispatch(LOAD_CARD());
        } else {
          const pack = r.proposal as PurchasedPack;
          if (pack !== undefined) {
            dispatch(
              ADD_TO_CARD({
                id: 1,
                addDate: pack.purchaseDate,
                packId: pack.id,
              })
            );
          }
        }

        showMessage("Votre pack a bien été ajouté !");
      }
    } catch (e) {
      showMessage("Une erreur est survenue.");
    }

    setAdding(false);
  };

  const getCoaches = async () => {
    if (state.login.loggedType === LoggedType.None) {
      history.push('/acces-membre/client');
      return;
    }
    if (loading) return;
    setLoading(true);
    setReservations([]);
    onModalDismiss();
    if (speciality > 0 && location !== undefined && dispos.length > 0) {
      try {
        const r = await pickCoach(
          userChoose,
          location.placeId,
          location.description,
          speciality,
          dispos,
          []
        );

        if (r.length > 0) {
          if (!userChoose) {
            openModal(r[0]);
          } else {
            setReservations(r);
          }
          scroll();
        } else {
          showMessage("Nous sommes désolés, aucun coach n’est disponible sur votre créneaux, envoyez nous par votre demande pour une recherche approfondie ! Contact: contact@hoplacoaching.fr ou au 0670848185 (appel, sms, whatsapp).");
        }
      } catch (e) {
        showMessage("Une erreur est survenue");
      }
    } else {
      showMessage(
        "Des informations sont manquantes pour vous proposer un coach !"
      );
    }

    setLoading(false);
  };

  const openModal = (prop: ProposedCoach) => {
    setProposition(prop);
    showModal(
      <RecapSeance
        canConfirm={true}
        proposition={prop}
        onClose={(e) => onPropositionClosed(e)}
        speciality={speciality}
        location={location}
        price={state.reservation.sessionPrice}
      />,
      "Récapitulatif de la séance",
      true
    );
  };

  const onModalDismiss = () => {
    if (!userChoose) {
      setProposition(undefined);
    }

    setIsModalOpen(false);
  };

  const onPropositionClosed = (accepted: boolean) => {
    setIsModalOpen(false);

    if (accepted) {
      setReservations([]);
    }
  };

  const [currentPromotion, setPromotion] = useState<Reduction>();

  useEffect(() => {
    const get = async () => {
      try {
        const r = await getPromotion();

        if (r) {
          setPromotion(r);
        }
      } catch (e) {

      }
    };

    get();
  }, []);

  useEffect(() => {
    if (state.reservation.propositions.length > 0) {
      setProposedReservations(state.reservation.propositions);
      setUserChoose(true);
    }

    if (state.reservation.dispos.length > 0) {
      setDisposReservations(state.reservation.dispos);
    }

    if (state.reservation.specialityId > -1) {
      setSpecialityReservation(state.reservation.specialityId);
    }

    if (state.reservation.location !== undefined && state.login.loggedType === LoggedType.None) {
      setLocationReservation(state.reservation.location);
    }
  }, []);

  return (
    <>
      {/* Bloc Recherche séance */}
      <RechercheSeance
        loading={loading}
        userChoose={userChoose}
        dispos={dispos}
        specialityId={speciality}
        location={location}
        handleSubmit={() => getCoaches()}
        setSpeciality={setSpeciality}
        setLocation={setLocation}
        setDispos={setDispos}
        setUserChoose={(e) => {
          setReservations([]);
          setUserChoose(e);
        }}
      />

      {/* <Banniere
        title="Et Hop là une reduc !"
        content1={'Avec votre pack 10 séances, on se dépense plus en dépensant moins  !'}
        content2="Pourquoi prendre un pack 10 séances "
        content={<PackComponent />}
        contentTitle="Pourquoi prendre un pack 10 séances ?"
      /> */}

      {/* Les coachs disponibles */}
      {!userChoose || reservations.length === 0 ? (
        ""
      ) : (
        <Box ref={ref}>
          {/* <NotreSelection coach={reservations[0]} openModal={openModal} /> */}
          <Coachs reservations={reservations} openModal={openModal} />
        </Box>
      )}

      {/* Les cours disponibles */}
      <Cours />

      {currentPromotion ? (
        // <Banniere
        //   title={currentPromotion.text}
        //   content1={`${reduc} de réduction sur votre 1ère séance avec le code : ${currentPromotion.code}`}
        // />
        <ReductionBanner
          title={currentPromotion.text}
          subTitle={`avec le code « ${currentPromotion.code} »`}
        />
      ) : (
        ""
      )}

      <DefiscalisationBanner />

      {/* Coach du mois */}
      <CoachDuMois />
    </>
  );
};

export default ClientMainPage;
