import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { ClientPrivate, Client, UpdateUserResult } from "../models/Client";
import { Location } from "../models/Location";
import { checkClientAI, getClientPrivate, updateClient } from "../services/clientService";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import classes from "../style";
import { Image } from "../components/Image";
import { PlaceAutocomplete } from "../components/PlaceAutocomplete";
import { PlanningSlotViewer } from "../components/PlanningSlotViewer";
import { PlanningSlot } from "../models/Planning";
import { showMessage, showModal } from "../services/popupService";
import { PasswordForm } from "../components/PasswordForm";
import { validateEmail, validatePhone } from "../utils/manyUtils";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { LoggedType } from "../slices/loginSlice";
import Fond from '../images/FondProfil.jpg';
import CircleIcon from '@mui/icons-material/Circle';
import { getClientFuturHistory, getClientPastHistory, getClientPastHistoryCount } from "../services/planningService";
import { IonButton } from "@ionic/react";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ClientTuto } from "../components/ClientTuto";
import { Helmet } from "react-helmet";

const customPlaceStyle = {
  menuPortal: (base: any) => ({
    ...base,
    zIndex: 99999,
    minWidth: "100px",
    "&:after": {
      position: "absolute",
      zIndex: 9999,
      content: '""',
      left: "20px",
      top: "0px",
      width: 0,
      height: 0,
      borderLeft: "10px solid transparent",
      borderRight: "10px solid transparent",
      borderBottom: "16px solid #000",
    },
  }),
  control: (provided: any) => ({
    ...provided,
    backgroundColor: "transparent",
    height: "37px",
    borderRadius: 0,
    borderTop: 0,
    borderRight: 0,
    borderLeft: 0,
    borderColor: "#000",
    boxShadow: "none",
    minHeight: "29px",
    "&:hover": {
      borderColor: "#000",
    },
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    fontSize: "14px",
    padding: 0,
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    backgroundColor: "transparent",
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: "#000 !important",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    color: state.isSelected || state.isFocused ? "#8C6AD4" : "#000",
    background: "none",
    "&:hover": {
      color: "#8C6AD4",
      background: "none",
    },
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    border: "2px solid #000",
    marginTop: "15px",
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    color: "#000",
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    fontSize: "18px",
    fontStyle: "italic",
    color: "#000",
    opacity: 0.7,
  }),
};

export const ClientPage: React.FC = () => {
  const state = useSelector((s: RootState) => s);
  const [loading, setLoading] = useState(false);
  const [priv, setPriv] = useState(false);
  const [clientId, setClientId] = useState(0);

  const [privData, setPrivData] = useState<ClientPrivate>();
  const [client, setClient] = useState<Client>();

  const [editable, setEditable] = useState(false);

  const history = useHistory();

  const [phone, setPhone] = useState<string>();
  const [location, setLocation] = useState<Location>();
  const [image, setImage] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const [futurSlots, setFuturSlots] = useState<PlanningSlot[]>([]);
  const [pastSlots, setPastSlots] = useState<PlanningSlot[]>([]);
  const [saving, setSaving] = useState(false);

  const [page, setPage] = useState(0);
  const [maxPage, setMaxPage] = useState(1);

  if (clientId === 0) {
    const query = new URLSearchParams(window.location.search);
    const queryId = query.get("id") ?? "";
    const cId = parseInt(queryId);

    if (cId === undefined || cId <= 0) {
      history.push("");
    } else {
      setClientId(cId);
      setPriv(
        state.login.loggedType == LoggedType.Client
          ? state.login.client?.id == cId
          : false
      );
    }
  }

  const leave = () => {
    history.push("/");
  };

  const savePrivate = async () => {
    if (saving) return;

    if (!validateEmail(email)) {
      showMessage("Veuillez saisir une adresse email valide.");
      return;
    }

    if (!validatePhone(phone ?? "")) {
      showMessage("Veuillez saisir un numéro de téléphone valide.");
      return;
    }

    setSaving(true);

    try {
      const result = await updateClient(
        email,
        image,
        location ?? null,
        phone ?? null
      );

      if (result === UpdateUserResult.OK) {
        showMessage("Informations sauvegardées !");
      } else {
        showMessage("Une erreur est survenue.");
      }
    } catch (e) {
      showMessage("Une erreur est survenue.");
    }

    setSaving(false);
  };

  const updateUrssaf = async () => {
    if (loading) return;
    setLoading(true);

    try {
      if (privData !== undefined) {
        const canAI = await checkClientAI();
        setPrivData({
          ...privData,
          canAI
        });

        let msg = '';

        if (canAI) {
          msg = 'Votre compte a bien été crée !';
        }
        else {
          msg = `Aucun compte avec votre adresse email n'a été trouvé, veuillez attendre et réessayer.`;
        }

        showMessage(msg);
      }
    } catch {

    }

    setLoading(false);
  }

  const setHistoryPage = async (nextPage: number) => {
    if (nextPage < 0 || nextPage >= maxPage) return;

    setPastSlots(await getClientPastHistory(nextPage * 10, 10));
    setPage(nextPage);
  }

  const getHistory = async () => {
    try {
      if (priv) {
        const count = await getClientPastHistoryCount();
        const maxPage = Math.round(count / 10) + 1;

        setMaxPage(maxPage);
        setFuturSlots(await getClientFuturHistory());
        setPastSlots(await getClientPastHistory(0, 10));
      }
    } catch (e) {
      //
    }
  };

  const infosLabel = priv ? "Mes informations" : "Informations";

  useEffect(() => {
    const loadClient = async () => {
      try {
        const r = await getClientPrivate(clientId);
        if (r !== undefined) {
          setClient(r.client);
          setPrivData(r);

          setLocation(r.address);
          setPhone(r.client.phoneNumber);
          setEmail(r.email);
          getHistory();
        } else {
          leave();
        }
      } catch (e) {
        // message d'erreur
        leave();
        throw e;
      }
    };

    if (clientId > 0) {
      loadClient();
    }
  }, [clientId]);

  return (
    <>
      <Helmet>
        <link ref="canonical" href="https://www.hoplacoaching.fr/boxe-entrainement" />
      </Helmet>
      <Box sx={{
        background: `url(${Fond})`,
        backgroundRepeat: "no-repeat", backgroundSize: 'cover',
        mt: 10
      }}>
        {client !== undefined ? (
          <Box sx={{
            paddingTop: {
              sm: '50px',
              xs: '0xp'
            }, margin: '0px 0px 0px 0px'
          }}>
            {priv && !(privData?.canAI ?? false) && <ClientTuto />}
            <Box className="profileClientBloc" mx='10%'>

              <Typography variant="h3" color='#f36b29' fontWeight='bold' ml={5}>{infosLabel}</Typography>
              <Box sx={{
                backgroundColor: '#a2c2c2', my: 5, p: 5, mb: 10
              }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={3}
                    display="flex"
                    flexDirection='column'
                    alignItems='center'
                    justifyContent="center">
                    <Image
                      userId={client.id}
                      isClient={true}
                      onImageChanged={setImage}
                      editable={editable} circle
                    />
                    <Typography variant="h5" fontWeight='bold'>
                      {client.firstname} {client.lastname}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={5}
                    textAlign={{ xs: "center", md: "start" }}
                  >
                    <Box my={2}>
                      <Typography variant="h5" fontWeight='bold'>
                        Téléphone
                      </Typography>
                      {editable ? (
                        <TextField
                          hiddenLabel
                          id="phone"
                          placeholder="+33..."
                          defaultValue={phone}
                          variant="filled"
                          size="small"
                          sx={classes.input}
                          onChange={(e) => setPhone(e.target.value!)}
                        />
                      ) : (
                        <Typography variant="h5">{phone}</Typography>
                      )}
                    </Box>
                    <Box my={2}>
                      <Typography variant="h5" fontWeight='bold'>
                        Adresse mail
                      </Typography>
                      {editable ? (
                        <TextField
                          hiddenLabel
                          id="email"
                          defaultValue={email}
                          variant="filled"
                          size="small"
                          sx={classes.input}
                          onChange={(e) => setEmail(e.target.value!)}
                        />
                      ) : (
                        <Typography variant="h5">{email}</Typography>
                      )}
                    </Box>
                    {editable ? (
                      <Box my={2}>
                        <Typography variant="h5" fontWeight='bold'>
                          Confirmation adresse mail
                        </Typography>
                        {editable ? (
                          <TextField
                            hiddenLabel
                            id="email"
                            variant="filled"
                            size="small"
                            sx={classes.input}
                            onChange={(e) => setEmail(e.target.value!)}
                          />
                        ) : (
                          <Typography variant="h5">{email}</Typography>
                        )}
                      </Box>
                    ) : (
                      <></>
                    )}
                    <Box my={2}>
                      <Typography variant="h5" fontWeight='bold'>
                        Adresse
                      </Typography>
                      {editable ? (
                        <PlaceAutocomplete
                          onChange={setLocation}
                          initValue={location}
                          disabled={!editable}
                          customStyle={customPlaceStyle}
                          noOptionsMessage="J'habite au ..."
                        />
                      ) : (
                        <Typography variant="h5">
                          {location?.description}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  {priv ? (
                    <Grid
                      item
                      xs={12}
                      md={4}
                      display="flex"
                      flexDirection="column"
                      justifyContent="left"
                      alignItems="start"
                    >
                      <Typography variant="h5" fontWeight='bold'>
                        Status compte Urssaf
                      </Typography>
                      <Box display='flex' flexDirection='row' justifyContent='center' alignContent='center' alignItems='center'>
                        <CircleIcon sx={{ color: privData?.canAI ?? false ? 'green' : 'red' }} />
                        <Typography variant="h5" ml={1}>{(privData?.canAI ?? false) ? 'validé' : 'inactif'}</Typography>
                      </Box>
                      <Button variant='contained' size='large' onClick={() => {
                        if (editable) {
                          setEditable(false);
                          savePrivate();
                        } else {
                          setEditable(true);
                        }
                      }}
                        sx={{
                          borderRadius: 20, fontSize: '1.3em', my: 3, width: '200px'
                        }}>
                        {editable ? 'Sauvegarder' : 'Modifier'}
                      </Button>
                      <Button variant='contained' size='large' onClick={(e) =>
                        showModal(
                          <PasswordForm />,
                          "Changement de mot de passe",
                          true
                        )
                      }
                        style={{
                          borderRadius: 20, fontSize: '1.3em', width: '200px'
                        }}>
                        Mot de passe
                      </Button>
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
              </Box>
            </Box>

            {priv ? (
              <>
                <Box sx={{
                  backgroundColor: '#0a0d11',
                  height: '150px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                  <Typography variant="h2" color='white' align='center'>
                    MES SÉANCES
                  </Typography>
                </Box>

                {futurSlots.length > 0 &&
                  <Box className="profileClientBloc" mx='10%' mb={5} mt={3}>
                    <Typography variant="h3" color='#f36b29' fontWeight='bold' ml={5}>À venir</Typography>
                    {futurSlots
                      .map((s, i) => (
                        <PlanningSlotViewer
                          key={i}
                          slot={s}
                          updated={() => getHistory()}
                          onClose={() => { }}
                          small={true}
                        />
                      ))}
                  </Box>
                }
                {pastSlots.length > 0 &&
                  <Box mx='10%' mb={5} mt={3}>
                    <Typography variant="h3" color='#f36b29' fontWeight='bold' ml={5}>Passées</Typography>
                    {pastSlots
                      .map((s, i) => (
                        <PlanningSlotViewer
                          key={i}
                          slot={s}
                          updated={() => getHistory()}
                          onClose={() => { }}
                          small={true}
                          customStyle={{
                            backgroundColor: 'transparent',
                            border: '2px solid black',
                            my: 5, p: 5
                          }}
                        />
                      ))}
                  </Box>
                }
                {maxPage > 1 && (
                  <Box display='flex' justifyContent='end' alignItems='end' sx={{ alignItems: 'center' }} mx='10%' mb={5} mt={3}>
                    {page > 0 ?
                      <IconButton
                        onClick={() => setHistoryPage(page - 1)}>
                        <ArrowBackIosIcon />
                      </IconButton> : <Box sx={{ width: '30px', height: '30px' }} />
                    }
                    <Typography color='black'>{page + 1}</Typography>
                    {page < maxPage - 1 ?
                      <IconButton
                        onClick={() => setHistoryPage(page + 1)}>
                        <ArrowForwardIosIcon />
                      </IconButton> : <Box sx={{ width: '30px', height: '30px' }} />
                    }
                    <Typography color='black'>sur {maxPage}</Typography>
                  </Box>
                )
                }
                {/* <Box className="profileClientAvisBloc">
              <Typography sx={classes.label}>Vos derniers avis</Typography>
              <DerniersAvis />
            </Box> */}
              </>
            ) : (
              ""
            )}
            {
              state.login.loggedType === LoggedType.Client ?
                (<Box sx={{ backgroundColor: '#104f5c' }}>
                  <Grid container sx={{ pt: 10, pb: 10 }}>
                    <Grid item xs={12} sm={5}>
                      <Box sx={{
                        display: 'flex',
                        justifyContent: {
                          md: 'flex-end',
                          xs: 'center'
                        }
                      }}>

                        <Button variant='contained' size='large'
                          style={{
                            textTransform: 'none',
                            margin: '10px',
                            maxHeight: '50px', minWidth: '240px',
                            minHeight: '50px', borderRadius: 20, fontSize: '1.6em'
                          }}
                          onClick={() => history.push('/reservation')}>
                          Réserver une séance
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={2} />
                    <Grid item xs={12} sm={5}>
                      <Box sx={{
                        display: 'flex',
                        justifyContent: {
                          md: 'flex-start',
                          xs: 'center'
                        }
                      }}>

                        <Button variant='contained' size='large'
                          style={{
                            textTransform: 'none',
                            margin: '10px',
                            maxHeight: '50px', minWidth: '240px',
                            minHeight: '50px', borderRadius: 20, fontSize: '1.6em'
                          }}
                          onClick={() => window.open('mailto:contact@hoplacoaching.fr')}>
                          Nous contacter
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                ) : ''
            }
          </Box>
        ) : (
          <LoadingButton loading></LoadingButton>
        )}
      </Box>
    </>
  );
};
