import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Switch, Route, withRouter, useHistory, Router, useLocation } from "react-router-dom";
import HoplaTheme from "./theme";
import Layout from "./components/layout/index";

/* Theme variables */
import "./theme/variables.css";
import axios from "axios";
import { useDispatch } from "react-redux";
import { LOAD_SPECIALITIES } from "./slices/coachSlice";
import MainPage from "./pages/MainPage";
import { LoginPage } from "./pages/LoginPage";
import { ClientPage } from "./pages/ClientPage";
import { CoachPage } from "./pages/CoachPage";
import { getTokenEntry, removeToken } from "./services/cookieService";
import { RELOG_COACH, RELOG_USER, UNLOG_USER } from "./slices/loginSlice";
import { showMessage } from './services/popupService';
import { LOAD_PRICES } from "./slices/reservationSlice";
import CookieConsent from "react-cookie-consent"
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { DefiscalisationPage } from "./pages/DefiscalisationPage";
import { GymDoucePage } from "./pages/GymDoucePage";
import { BoxePage } from "./pages/BoxePage";
import { FitnessPage } from "./pages/FitnessPage";
import { ForgotPasswordPage } from "./pages/ForgotPasswordPage";
import { MentionsLegales } from './pages/MentionsLegales';
import { CgvPage } from "./pages/CgvPage";
import { getSeoTitle } from "./seo/seoTitle";
import { Helmet } from "react-helmet";
import { getSeoMetadata } from "./seo/seoMetadata";
import ClientMainPage from "./pages/ClientMainPage";
import { Faq } from "./pages/Faq";
import { Article1 } from "./pages/Articles/Article1";
import { Article2 } from "./pages/Articles/Article2";
import { Article3 } from "./pages/Articles/Article3";
import { Article4 } from "./pages/Articles/Article4";
import { Article5 } from "./pages/Articles/Article5";
import { Article6 } from "./pages/Articles/Article6";
import { Articles } from "./pages/Articles/Articles";

function App() {
  let theme = createTheme(HoplaTheme);
  theme = responsiveFontSizes(theme);

  const [title, setTitle] = useState('');
  const [canonicalUrl, setCanonicalUrl] = useState('');


  const location = useLocation();
  const history = useHistory();

  const declineCookies = () => {
    removeToken();
  }

  const unlogUser = (expired: boolean) => {
    dispatch(UNLOG_USER());

    if (!expired) {
      showMessage('Vous avez été deconnecté.');
    }

    history.replace('');
  }

  axios.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    if (error.response.status === 401 && (error.response.data === 'Token is expired.' || error.response.data === 'Token is outdated.')) {
      unlogUser(error.response.data === 'Token is expired.');
    }

    return Promise.reject(error);
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const entry = getTokenEntry();

    if (entry?.token) {
      if (entry.isClient) dispatch(RELOG_USER({ id: entry.id, token: entry.token }))
      else dispatch(RELOG_COACH({ id: entry.id, token: entry.token }))
    } else {
      dispatch(LOAD_SPECIALITIES());
      dispatch(LOAD_PRICES());
    }
  }, []);

  useEffect(() => {
    setTitle(getSeoTitle(location.pathname));
    setCanonicalUrl(`https://www.hoplacoaching.fr${location.pathname}`);
  }, [location]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Switch>
          <Layout>
            <Route path="/" component={MainPage} exact />
            <Route path="/reservation" component={MainPage} exact />
            <Route path="/acces-membre" component={LoginPage} exact />
            <Route path="/acces-membre/coach" component={LoginPage} exact />
            <Route path="/acces-membre/client" component={LoginPage} exact />
            <Route path='/forgot-password' component={ForgotPasswordPage} exact />
            <Route path="/client" component={ClientPage} exact />
            <Route path="/coach" component={CoachPage} exact />
            <Route path="/defiscalisation-immédiate" component={DefiscalisationPage} exact />
            <Route path='/gym-douce-bien-etre' component={GymDoucePage} exact />
            <Route path='/boxe-entrainement' component={BoxePage} exact />
            <Route path='/sport-et-fitness' component={FitnessPage} exact />
            <Route path='/conditions-generales-de-vente' component={MentionsLegales} exact />
            <Route path='/conditions-generales-de-vente-hoplacoaching' component={CgvPage} exact />
            <Route path='/faq' component={Faq} exact />

            <Route path="/articles" component={Articles} />
            <Route path="/article/coach-sportif-domicile-ile-de-france-avantages-benefices" component={Article1} />
            <Route path="/article/coaching-sportif-domicile-avance-immediate-payer-moitie-prix" component={Article2} />
            <Route path="/article/coaching-sportif-personnalise-domicile-femmes-actives" component={Article3} />
            <Route path="/article/tarifs-coach-sportif-domicile-ile-de-france" component={Article4} />
            <Route path="/article/perte-poids-coach-sportif-domicile-ile-de-france" component={Article5} />
            <Route path="/article/coaching-bien-etre-remise-en-forme-domicile-seniors-ile-de-france" component={Article6} />
          </Layout>
        </Switch>
      </ThemeProvider>
      <CookieConsent
        overlay={true}
        enableDeclineButton
        declineButtonText='Refuser'
        buttonText='Accepter'
        onDecline={declineCookies}
        containerClasses="cookies"
      >
        <Box style={{ height: '100px' }}>
          <Typography>Ce site utilise des cookies uniquement pour vous reconnecter.</Typography>
        </Box>
      </CookieConsent>
    </>
  );
}

export default withRouter(App);
